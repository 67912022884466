/**************** public *******************/
@import url('reset.css');
@import url('icon.css');
@import url('btn.css');
@import url('fonts.css');

@import url('selectCustom.css');
@import url('modal.css');
@import url('custom/menu.css');

/* lib */
@import url('swiper-bundle.min.css');

:root {
    --header-height: 90px;
    --color-main: #161617;
    --color-primary : #E6002D;
    --color-primary-sub : #ab0006;
    --color-primary-sub2 : #ff5557;
    --color-secondary : #00145A;
    --color-secondary-sub : #000031;
    --color-secondary-sub2 : #3d3a88;
    --color-tertiary : #409EFF;
    --color-table-bg : #1A1B1E;

    --color-nt : #fff;
    --color-nt-100 : #FAFAFA;
    --color-nt-200 : #EAEAEA;
    --color-nt-300 : #E1E1E1;
    --color-nt-400 : #BDBDBD;
    --color-nt-500 : #999999;
    --color-nt-600 : #6D6D6D;
    --color-nt-700 : #424242;
    --color-nt-800 : #121212;
    --color-nt-900 : #000;

    --color-gray : #28292E;
    --color-blue: #0172CB;
    --color-green: #28A138;
    --color-red: #D21C1C;
    --color-orange: #F9971E;
    --border-color: #D9D9D9;
}
body {
    background-color: var(--color-main);
    color: #fff;
    width: 100%;
    height: 100%;
}
body.hide {
    /*overflow: hidden;*/
}

input,textarea,select {
    height: 56px;
    padding: 0 20px;
    background-color: transparent;
    border: 1px solid var(--color-gray);
    font-size: 16px;
    border-radius: 4px;
}
input::placeholder,
textarea::placeholder { color: var(--color-nt-600); }
input:focus,
textarea:focus,
select:focus {
    border-color: #fff !important;
}
input.verfiy,
textarea.verfiy,
select.verfiy {
    border-color: var(--color-red) !important;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(/public/image/asset/icon/arrow_drop_down.svg);
    background-position: right 16px center;
    background-repeat: no-repeat;
    padding-right: 56px;
    cursor: pointer;
}
select:hover {
    /*background-color: var(--color-gray);*/
}
hr { border-color: var(--border-color); margin: 16px 0;}

.els {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.els2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.txt_display {
    font-size: 72px;
    font-weight: 700;
}
.txt_display2 {
    font-size: 64px;
    font-weight: 700;
}
.txt_head {
    font-size: 48px;
    font-weight: 700;
}
.txt_head2 {
    font-size: 34px;
    font-weight: 700;
}
.txt_head3 {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
}
.txt_head4 {
    font-size: 24px;
    font-weight: 700;
}
.txt_head5 {
    font-size: 20px;
    font-weight: 700;
}
.txt_head6 {
    font-size: 18px;
    font-weight: 700;
}
.txt_head7 {
    font-size: 14px;
    font-weight: 700;
}
.txt_sub {
    color: var(--color-nt-600);
}
.txt_primary {
    color: var(--color-primary);
}
.txt_live {
    background-color: var(--color-primary);
    color: #fff;
    font-weight: 700;
    padding: 6px 16px;
    border-radius: 30px;
}
.txt_bold { font-weight: 700; }
.txt_semi { font-weight: 600; }
.txt_md { font-weight: 500; }
.txt_tiny { font-weight: 300;}
.txt_time {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.txt_pre {
    white-space: pre-line;
}
.txt_centerY {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.txt_time .icon {
    margin-right: 6px;
}
.txt_alert {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.txt_alert:before {
    flex-shrink: 0;
    content: "·";
    width: 24px;
    height: 24px;
    font-size: 30px;
    text-align: center;
}

.fl_center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fl_center_top {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.fl_center_end {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.fl_top_center {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.fl_bt_center {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.u_label {
    font-size: 14px;
    padding: 4px 14px;
    border-radius: 20px;
    background-color: var(--color-gray);
}
.u_label.success {
    background-color: var(--color-green);
}

.golink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.golink:after {
    content: "";
    width: 24px;
    height: 24px;
    background-image: url(/public/image/asset/icon/golink.svg);
    background-repeat: no-repeat;
    background-position: center;
}

/* margin */
.mt_120 { margin-top: 120px; }
.mt_64 { margin-top: 64px; }
.mt_48 { margin-top: 48px; }
.mt_32 { margin-top: 32px; }
.mt_24 { margin-top: 24px; }
.mt_16 { margin-top: 16px; }
.mt_12 { margin-top: 12px; }

.mb_120 { margin-bottom: 120px; }
.mb_64 { margin-bottom: 64px; }
.mb_48 { margin-bottom: 48px; }
.mb_40 { margin-bottom: 40px; }
.mb_32 { margin-bottom: 32px; }
.mb_28 { margin-bottom: 28px; }
.mb_24 { margin-bottom: 24px; }
.mb_16 { margin-bottom: 16px; }
.mb_12 { margin-bottom: 12px; }
.mb_4 { margin-bottom: 4px; }

.ml_20 { margin-left: 20px; }
.ml_15 { margin-left: 15px; }
.ml_10 { margin-left: 10px; }

.main-container {
    background-color: transparent;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    min-height: 100%;
    z-index: 1;
}
.inner {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 16px;
}

/* checkbox */
.checkbox {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.checkbox > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.checkbox > input + label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-nt-500);
}
.checkbox > input + label:before {
    flex-shrink: 0;
    content: "";
    margin-right: 12px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 24px;
    height: 24px;
}
.checkbox > input[type="radio"] + label {
    color: #fff;
}
.checkbox > input[type="radio"] + label:before {
    background-image: url(/public/image/asset/icon/check_rd_false.svg);
}
.checkbox > input[type="radio"]:checked + label:before {
    background-image: url(/public/image/asset/icon/check_rd_true.svg);
}
.box__type01 > input + label:before {
    background-image: url(/public/image/asset/icon/check_sq_false.svg);
}
.box__type01 > input:checked + label:before {
    background-image: url(/public/image/asset/icon/check_sq_true.svg);
}
.box__type02 > input + label:before {
    background-image: url(/public/image/asset/icon/check_cr_false.svg);
}
.box__type02 > input:checked + label:before {
    background-image: url(/public/image/asset/icon/check_cr_true.svg);
}

/* table */
.table__area {}
.table__area table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}
.table__area table th {
    padding: 16px 26px;
    color: var(--color-nt-400);
}
.table__area table td {
    border-bottom: 1px solid var(--color-gray);
    padding: 8px 23px;
}
/* */

/* mobile gnb*/
.mobile___gnb__wrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 7800;
}
.mobile___gnb__wrap .dim {
    position: absolute;
    left: -100vh;
    top: 0;
    width: 100vh;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 0;
    z-index: 10;
}
.mobile___gnb__wrap .m__gnb {
    position: absolute;
    left: -288px;
    top: 0;
    width: 288px;
    height: 100%;
    padding-top: 56px;
    background-color: var(--color-main);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    z-index: 100;
}
.mobile___gnb__wrap.active .dim,
.mobile___gnb__wrap.active .m__gnb {
    left: 0;
    opacity: 1;
}
.m__gnb .gnb__area {
    padding: 24px;
    height: 100%;
    overflow-y: scroll;
}
.m__gnb .m_gnb_tit {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.m__gnb .m_gnb_tit,
.m__gnb .m_gnb_tit > a {
    font-size: 20px;
    font-weight: 700;
}
.m__gnb .m_gnb_tit.drop:after {
    content: "";
    width: 9px;
    height: 9px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 9px;
    background-image: url(/public/image/asset/icon/ic_plus.svg);
}
.m__gnb .m_gnb_tit.drop.active:after {
    background-image: url(/public/image/asset/icon/ic_minus.svg);
}
.m_gnb_sub.drop:after {
    content: "";
    width: 9px;
    height: 9px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 9px;
    background-image: url(/public/image/asset/icon/ic_plus_gray.svg);
    margin-left: 8px;
}
.m_gnb_sub.drop.active:after {
    background-image: url(/public/image/asset/icon/ic_minus_gray.svg);
}
.m_depth {
    display: none;
    margin-bottom: 24px;
}
.m_depth_sub {
    margin-top: 16px;
}
.m_depth a {
    padding-left: 24px;
}
.m_depth li {
    margin-bottom: 12px;
}
.m_depth li:last-child {
    margin-bottom: 24px;
}

.m_gnb_sub {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.m_gnb_sub.drop {
    color: var(--color-nt-600);
    font-weight: 700;
    padding-left: 8px;
}

.m_gnb_sub + .m_depth_sub {
    display: none;
}
.m_gnb_tit .m_depth {
    display: none;
}

.m_gnb_tit.active + .m_depth,
.m_gnb_sub.active + .m_depth_sub {
    display: block;
}


/* header */
.header__ {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    background-color: var(--color-primary);
    z-index: 8000;
}
.header__.black {
    background-color: var(--color-main);
}
.header__ .inner {
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hd__gng__area {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}
.hd__gng__area .logo {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 44px;
    margin-right: 52px;
}
.hd__gng__area .gnb__menu {
    flex-grow: 1;
    height: 100%;
}
.hd__gng__area .gnb__menu > ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}
.hd__gng__area .gnb__menu > ul > li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.hd__gng__area .gnb__menu > ul > li > a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 28px;
    height: 100%;
    font-size: 18px;
    font-weight: 700;
}
.hd__gng__area .gnb__menu > ul > li > a:hover,
.hd__gng__area .gnb__menu > ul > li.active > a {
    text-decoration: none;
    background-color: var(--color-primary-sub);
}
.hd__gng__area .gnb__menu li:hover .gnb__depth {
    display: block;
}

.gnb__depth {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: var(--color-main);
    padding-left: 227px;
    z-index: 10;
    overflow-y: auto;
    max-height: calc(100vh - var(--header-height));
}
.gnb__depth .inner {
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
    flex-wrap: wrap;
}
.depth__menu__area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 56px 48px;
}
.depth__menu__area:first-child {
    /*padding-left: 0;*/
}
.depth__menu__area .title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 48px;
}
.depth__menu__area .menu + .menu {
    margin-left: 50px;
}
.depth__menu__area .menu li a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
}
.depth__menu__area .menu li a:hover,
.depth__menu__area .menu li.active a {
    text-decoration: none;
    color: var(--color-primary);
}

.depth__menu__area.col {
    padding: 0;
}
.depth__menu__area.col .menu ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.depth__menu__area.col .menu li a {
    padding: 28px 24px;
}

/* header tool */
.hd__tool__area {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 48px;
}
.hd__tool__area a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    padding: 16px;
}
.hd__tool__area a:hover .icon {
    opacity: 0.8;
}
.hd__tool__area a.active {
    text-decoration: underline;
    text-underline-position: under;
}
/**/

/* footer */
.footer__ {
    flex-shrink: 0;
    width: 100%;
    font-size: 14px;
    background-color: var(--color-nt-800);
    color: var(--color-nt-400);
}
.footer__ .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ft__gng__area ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    padding: 34px 0;
}
.ft__gng__area li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}
.ft__gng__area li:first-child {
    padding-left: 0;
}
.text_copyright {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
/**/

/* container */
.header__ + .container__ {
    padding-top: var(--header-height);
}
.container__ {
    width: 100%;
    flex-grow: 1;
}
.container__ > .inner {
    padding-top: 64px;
    padding-bottom: 120px;
}
.container__ > .inner.max-sizeX {
    max-width: 100%;
    padding: 0;
    overflow: hidden;
}
/**/


/* search */
.search__wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.search__inp__area {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}
.search__inp {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 420px;
    height: 100%;
}
.search__inp.auto-size {
    width: auto;
    max-width: 236px;
}
.search__inp input {
    flex-grow: 1;
}
.search__inp .searchBtn {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    width: 56px;
    height: 56px;
    cursor: pointer;
    border: none;
}
.search__inp + .btn {
    margin-left: 8px;
}

.search_support_area {

}

.search__tag__area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
}
.search__tag__area .u_label {
    cursor: pointer;
}

.search__tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 24px;
}
.search__tag .text {
}
.search__tag .delete {
    margin-left: 6px;
    cursor: pointer;
}

.search__field__group {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.search__field__group .item {
    flex-grow: 1;
    margin-right: 16px;
}
.search__field__group .search__inp {
    max-width: 236px;
}
.search__field__group .search__inp input {
    width: 100%;
}
.search__field__group .select__wrap {
    flex-shrink: 0;
    width: 190px;
    max-width: 190px;
}
.search__field__group .btn {
    width: 104px;
    height: 56px;
}

.search__field__group .item__gorup {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
/**/

/* file upload */
.file__upload__wrap {
    position: relative;
    width: 100%;
}
.file__upload {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.file__upload .upload__inp {
    position: relative;
    flex-grow: 1;
    margin-right: 8px;
}
.file__upload .upload__inp input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.file__upload .upload__inp input + label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    padding: 0 14px;
    background-color: transparent;
    border: 1px solid var(--color-gray);
    font-size: 16px;
    border-radius: 4px;
}
.file__upload .upload__inp .placeholder {
    color: var(--color-nt-500);
}
.file__upload .btn {
    flex-shrink: 0;
    width: auto;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
}

.file__progress__area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    margin-top: 10px;
    font-size: 13px;
}
.file__progress__area .state__text {
    flex-shrink: 0;
    padding: 2px 8px;
    border-radius: 30px;
    background-color: var(--color-nt-700);
    margin-right: 20px;
}
.file__progress__area .uploadFile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.file__progress__area .uploadFile .icon {
    margin-left: 8px;
    cursor: pointer;
}
.file__progress__area .state__text.upload {
    background: #0172CB;
}
.file__progress__area .state__text.success {
    background: #168D26;
}
.file__progress__area .state__text.pause {
    background: #D78623;
}

.progressBar__wrap {
    flex-grow: 1;
}
.progress__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.progress__text .precent {
    font-weight: 700;
    color: var(--color-blue);
}
.progressBar {
    display: block;
    width: 100%;
    height: 3px;
    margin-top: 5px;
    -webkit-appearance: none;
}
.progressBar::-webkit-progress-bar {
    background: var(--color-gray);
}
.progressBar::-webkit-progress-value {
    background: var(--color-blue);
}

/* date time */
.datetime_area {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.dateTimer_inp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 398px;
}
.dateTimer_inp input {
    min-width: 167px;
}
.dateTimer_inp span {
    width: 16px;
    text-align: center;
}

input[type="date"]{
    position: relative;
    padding-right: 60px;
    background-image: url(/public/image/asset/icon/ic_calendar.svg);
    background-repeat: no-repeat;
    background-position: right 22px center;
    cursor: text;
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
    display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    background: transparent;
    cursor: pointer;
    z-index: 1;
}

/* hash tag */
.hash__tag__area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.hash__tag__area .txt_tag {
    padding: 8px 16px;
    border-radius: 30px;
    border: 1px solid var(--color-gray);
    margin-right: 16px;
}
/**/


/* content */
.content__wrap {
    width: 100%;
}
.content_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content_head .head_title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.content_head .content_tab li + li:before {
    height: 30px;
    top: 11px;
}
.content_head .select__wrap {
    min-width: 190px;
}

.head_title .txt_sub {
    margin-left: 12px;
    margin-bottom: 8px;
}
.head__tool {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.head__tool .btn-revers {
    margin-left: 8px;
    padding: 0;
    min-width: 56px;
}
.head__tool .select__wrap,
.head__tool select {
    min-width: 190px;
}
.head__tool .btn + .btn {
    margin-left: 8px;
}

.max-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
}
.max-content > .inner {
    flex-grow: 1;
    width: 100%;
    height: 100%;
}
/**/

/* player area */
.video__player__area {
    position: relative;
    padding-bottom: 62.6%;
    background-color: var(--color-gray);
}

/* video card */
.video__card {}
.video__card .txt_subject.time {
    min-width: 60px;
    max-width: 60px;
    white-space: pre-line;
}

.video__card div.txt_sub span + span {
    border-left: 2px solid var(--color-gray);
    margin-left: 10px;
    padding-left: 10px;
}

.video__prev {
    position: relative;
    padding-bottom: 56.3%;
    background-color: var(--color-gray);
}
.video__prev .video_thum {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.video_thum .poster {
    width: 100%;
    height: 100%;
}
.thum_mask  {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.thum_mask .live_state {
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px 20px;
}
.thum_mask .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    text-align: center;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: 1px solid #fff;
}
.video__info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    padding-top: 24px;
    font-size: 14px;
}
.video__info .txt_subject {
    font-size: 18px;
    margin-top: 4px;
    margin-bottom: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/**/

/* video list */
.game__area {}
.game__area .block__list > ul  {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
}
.game__area .block__list > ul > li {
    flex: 0 1 25%;
    margin-right: 32px;
    max-width: calc(25% - 24px);
    margin-bottom: 48px;
    border-radius: 5px;
}
.game__area .block__list > ul > li:nth-child(4n) {
    margin-right: 0;
}
.block__list a {
}
.block__list a:hover {
    text-decoration: none;
}
.block__list a:hover .txt_subject {
    text-decoration: underline;
}



/* mobile size */
.game__area .block__list.size__mb > ul {
    flex-flow: column;
}
.game__area .block__list.size__mb > ul > li {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
    /*padding: 12px;*/
}
.game__area .block__list.size__mb > ul > li > a {
    display: block;
    padding: 12px;
}
.game__area .block__list.size__mb > ul > li:hover {
    background-color: var(--color-gray);
}

.size__mb .video__card {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.size__mb .video__prev {
    flex-shrink: 0;
    width: 171px;
    padding-bottom: 96px;
    margin-right: 24px;
}
.size__mb .video__info {
    flex-grow: 1;
    padding-top: 0;
}
.size__mb .video__info .txt_subject{
    font-size: 16px;
    margin-bottom: 4px;
    height: 3em;
}
/**/


/* pagenation */
.pagenate__area ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagenate__area ul li + li {
    margin-left: 8px;
}
.pagenate__area a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: var(--color-gray);
    color: #fff;
}
.pagenate__area li.active a {
    background-color: #fff;
    color: var(--color-nt-800);
}

.prev_thum {
    width: 85px;
    height: 48px;
    margin: 0 auto;
}
.prev_thum img {
    max-width: 100%;
    max-height: 100%;
}
/**/


/* order content */
.order__wrap {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.order__item {
    flex-grow: 1;
    width: auto;
}
.order__sub {
    flex-grow: 0;
    flex-shrink: 0;
    width: 395px;
    margin-left: 32px;
}

.order__twin .order__item {
    flex-grow: 1;
    max-width: 50%;
}
.order__twin .order__item + .order__item {
    margin-left: 64px;
}
/**/


/* div table */
.div__table__area {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-flow: column;
    border-top: 1px solid var(--color-gray);
}
.div__table__area .row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}
.div__table__area .row dl {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    border-bottom: 1px solid var(--color-gray);
    width: 100%;
}
.div__table__area .row dl dt {
    flex-shrink: 0;
    width: 150px;
    color: var(--color-nt-400);
    background-color: var(--color-table-bg);
    padding: 16px 20px;
}
.div__table__area .row dl dt.th_sd {
    width: 96px;
}
.div__table__area .row dl dt + dd {
    padding-left: 20px;
}
.div__table__area .row dl dd {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    min-height: 56px;
}
.div__table__area .row dl dd input {
    height: 40px;
    flex-grow: 1;
    padding: 0 14px;
}
.div__table__area .row dl dd input + .btn {
    flex-shrink: 0;
    margin-left: 8px;
}

.div__table__area.table__row__sub .row dl + dl dt {
    margin-left: 48px;
}
/**/

/* mobile trans table*/
.trans_table__area {}
.trans_table__area img {
    max-width: 100%;
}
.trans_table__area .box__type01 > input + label:before {
    margin-right: 0;
}
.trans_table__area .table__head {
    background-color: var(--color-table-bg);
}
.trans_table__area .table__body {

}
.trans_table__area .table__body dl + dl {
    border-top: 1px solid var(--color-gray);
}
.trans_table__area dl {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.trans_table__area dt,
.trans_table__area dd {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 56px;
    padding: 16px;
}
.trans_table__area dt {
    justify-content: center;
    color: var(--color-nt-400);
}
.trans_table__area dd {
    word-break: break-all;
}

.tableSelect {
    width: 190px;
}

/* ì•Œë¦¼ */
.notice_table dt:nth-child(1),
.notice_table dd:nth-child(1) {
    max-width: 72px;
    flex-shrink: 0;
    justify-content: center;
}

.notice_table dt:nth-child(2),
.notice_table dd:nth-child(2) {
    max-width: 200px;
    flex-shrink: 0;
    justify-content: center;
}

/* ì—…ë¡œë“œ */
.upload_table dt,
.upload_table dd {
    justify-content: center;
}
.upload_table dt:nth-child(1),
.upload_table dd:nth-child(1) {
    flex-shrink: 0;
    width: 47px;
    padding: 0 10px;
}

.upload_table dt:nth-child(2),
.upload_table dd:nth-child(2) {
    flex-shrink: 0;
    width: 57px;
    padding: 0 10px;
}
.upload_table dt:nth-child(3),
.upload_table dd:nth-child(3) {
    flex-shrink: 0;
    width: 153px;
    padding: 0 10px;
}
.upload_table dt:nth-child(4),
.upload_table dd:nth-child(4) {
    flex-shrink: 0;
    width: 184px;
}
.upload_table dt:nth-child(5),
.upload_table dd:nth-child(5) {
    flex-shrink: 0;
    width: 187px;
}
.upload_table dt:nth-child(6),
.upload_table dd:nth-child(6) {
    flex-shrink: 0;
    width: 101px;
}
.upload_table dt:nth-child(7),
.upload_table dd:nth-child(7) {
    flex-shrink: 0;
    width: 87px;
}
.upload_table dt:nth-child(8),
.upload_table dd:nth-child(8) {
    flex-shrink: 0;
    width: 112px;
}

/* ë‹¤ìš´ë¡œë“œ */
.download_table {  }
.download_table img {
    max-width: 100%;
}
.download_table .btn {
    font-size: 14px;
    height: 32px;
    padding: 0 12px;
    margin-bottom: 4px;
}
.download_table dt:nth-child(1),
.download_table dd:nth-child(1) {
    width: 56px;
}
.download_table dt:nth-child(2),
.download_table dd:nth-child(2) {
    width: 106px;
    padding: 8px;
    text-align: center;
}
.download_table dt:nth-child(3),
.download_table dd:nth-child(3) {
    padding: 10px;
    width: 106px;
}
.download_table dt:nth-child(4),
.download_table dd:nth-child(4) {
    width: 236px;
}
.download_table dt:nth-child(5),
.download_table dd:nth-child(5) {
    width: 105px;
    padding: 8px;
    flex-flow: column;
    justify-content: center;
}
.download_table dt:nth-child(6),
.download_table dd:nth-child(6) {
    width: 105px;
    padding: 8px;
}
.download_table dt:nth-child(7),
.download_table dd:nth-child(7) {
    width: 105px;

}
.download_table dt:nth-child(8),
.download_table dd:nth-child(8) {
    width: 105px;
    padding: 8px;
    text-align: center;
}

/*ê´€ì‹¬ ì˜ìƒ*/
.favor_table {}
.favor_table dt,
.favor_table dd {
    justify-content: center;
}
.favor_table dt:nth-child(1),
.favor_table dd:nth-child(1) {
    width: 52px;
    padding: 8px;
}
.favor_table dt:nth-child(2),
.favor_table dd:nth-child(2) {
    width: 84px;
    padding: 8px;
}
.favor_table dt:nth-child(3),
.favor_table dd:nth-child(3) {
    width: 112px;
    padding: 8px;
    text-align: center;
}
.favor_table dt:nth-child(4),
.favor_table dd:nth-child(4) {
    width: 117px;
    text-align: center;
}
.favor_table dt:nth-child(5),
.favor_table dd:nth-child(5) {
    width: 74px;
}
.favor_table dt:nth-child(6),
.favor_table dd:nth-child(6) {
    width: 82px;
    padding: 6px;
}
.favor_table dt:nth-child(7),
.favor_table dd:nth-child(7) {
    width: 107px;
}
.favor_table dt:nth-child(8),
.favor_table dd:nth-child(8) {
    width: 99px;
}
.favor_table dt:nth-child(9),
.favor_table dd:nth-child(9) {
    width: 200px;
}

.favor2P_table {}
.favor2P_table dt,
.favor2P_table dd {
    justify-content: center;
}
.favor2P_table dt:nth-child(1),
.favor2P_table dd:nth-child(1) {
    width: 52px;
    padding: 8px;
}
.favor2P_table dt:nth-child(2),
.favor2P_table dd:nth-child(2) {
    width: 84px;
}
.favor2P_table dt:nth-child(3),
.favor2P_table dd:nth-child(3) {
    width: 112px;
    padding: 8px;
}
.favor2P_table dt:nth-child(4),
.favor2P_table dd:nth-child(4) {
    width: 117px;
    padding: 8px;
}
.favor2P_table dt:nth-child(5),
.favor2P_table dd:nth-child(5) {
    width: 93px;
    padding: 8px;
}
.favor2P_table dt:nth-child(6),
.favor2P_table dd:nth-child(6) {
    width: 97px;
    padding: 8px;
}
.favor2P_table dt:nth-child(7),
.favor2P_table dd:nth-child(7) {
    width: 112px;
    padding: 8px;
}
.favor2P_table dt:nth-child(8),
.favor2P_table dd:nth-child(8) {
    width: 260px;;
}
/**/

/* edio tool */
.edit__tool {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.edit__tool .btn {
    margin-right: 8px;
    font-size: 14px;
    padding: 0 12px;
}
.edit__tool .btn.active {
    background-color: var(--color-blue) !important;
}
/**/


/* timer input */
.timer__inp {
    position: relative;
}
.timer__inp input {
    width: 100%;
    height: 40px;
    padding: 0 14px;
}
.timer__inp .btn-spinner-up,
.timer__inp .btn-spinner-down {
    position: absolute;
    right: 0;
    width: 32px;
    height: 50%;
    border-left: 1px solid var(--color-gray);
    cursor: pointer;
    z-index: 1;
}
.timer__inp .btn-spinner-up {
    top: 0;
    border-bottom: 1px solid var(--color-gray);
}
.timer__inp .btn-spinner-down {
    bottom: 0;
}
.timer__inp .btn-spinner-up:before,
.timer__inp .btn-spinner-down:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.btn-spinner-up:before {
    background-image: url(/public/image/asset/icon/spinner_up.svg);
}
.btn-spinner-down:before {
    background-image: url(/public/image/asset/icon/spinner_down.svg);
}
.timer__inp .btn-spinner-up:hover,
.timer__inp .btn-spinner-down:hover {
    opacity: 0.8;
}
/**/


/* content tab */
.content__tab__area {}
.content_tab {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.content_tab li {
    position: relative;
    padding-right: 24px;
}
.content_tab li + li {
    padding-left: 24px;
}
.content_tab li + li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
    width: 1px;
    height: 20px;
    background-color: var(--color-gray);
    z-index: 1;
}
.content_tab .tab_title {
    color: var(--color-nt-600);
    padding-bottom: 8px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.content_tab li:hover .tab_title {
    color: var(--color-nt-400);
}
.content_tab li.active .tab_title {
    color: #fff;
    border-bottom: 2px solid #fff;
}
/**/

/* slide */
.max-sizeX .content_head,
.max-sizeX .card__slide  {
    position: relative;
    max-width: 1280px;
    padding: 0 16px;
    margin: auto;
}

.slide__content__wrap {
    position: relative;
    width: 100%;
}
.max-sizeX .card__slide {}
.max-sizeX .card__slide a:hover {
    text-decoration: none;
}
.card__slide .swiper-slide {
    padding-right: 32px;
    width: auto;
}
.card__slide .video__card {
    width: 395px;
}
.card__slide .video__info .txt_subject {
    font-size: 24px;
}
.swiper-control {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.swiper-button-next,
.swiper-button-prev {
    width: 120px;
    height: 222px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    background-color: transparent;
    margin-top: 0;
    border: none;
}
.swiper-button-next:after,
.swiper-button-prev:after {
    display: none;
}
.swiper-button-prev {
    left: 0;
    background-image: url(/public/image/asset/icon/slide_prev.svg);
}
.swiper-button-next {
    right: 0;
    background-image: url(/public/image/asset/icon/slide_next.svg);
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0;
}

.swiper-pagination-bullet {
    width: 40px;
    height: 2px;
    border-radius: 0;
    background-color: var(--color-nt-600);
    vertical-align: top;
    opacity: 1;
}
.swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 16px;
}
.swiper-pagination-bullet-active {
    background-color: var(--color-primary);
}

.pagenation-control .pagenation-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 90px;
}
.pagenation-control .swiper-pagination {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
}
.pagenation-control .swiper-btn-pause {
    width: 24px;
    height: 24px;
    margin-left: 32px;
    background-image: url(/public/image/asset/icon/ic_pause.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    cursor: pointer;
}
.pagenation-control .swiper-btn-pause.active {
    background-image: url(/public/image/asset/icon/ic_play.svg);
}


.visula__slide__wrap {
    position: relative;
}
.visual__slide .swiper-slide {
    width: 100%;
    height: 640px;
    overflow: hidden;
}
.visual__slide .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.visual__slide .swiper-button-next,
.visual__slide .swiper-button-prev {
    height: 640px;
}
.visual__slide .slide_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.visual__slide .slide_txt {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: 90px;
    padding-top: 108px;
    margin: 0 auto;
    z-index: 10;
}
.visual__slide .slide_number {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5;
    font-size: 180px;
    font-weight: 700;
    margin-bottom: -130px;
}
.visual__slide a {
    text-decoration: none;
}
.visual__slide .pagenation-control {
    position: absolute;
    left: 0;
    bottom: 156px;
    width: 100%;
    z-index: 15;
}
.visual__slide .slide_txt,
.visual__slide .pagenation-wrap {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}


.onAir__slide__wrap {
    position: relative;
    z-index: 100;
    margin-top: -76px;
}
.onAir__slide {  }
.onAir__slide .swiper-slide {
    padding-right: 0;
    margin: 0 16px;
    width: auto;
}
.onAir_card {
    width: 395px;
    background-color: #000;
    padding: 40px;
    padding-top: 24px;
}
.onAir_card.active {
    background-color: #001459;
}
.onAir_card.active .txt_sub { color: #fff; opacity: 0.5; }
.live_state { color: var(--color-nt-600); }
.live_state {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
}
.live_state:before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--color-nt-600);
    margin-right: 8px;
}
.live_state.onAir {
    color: #fff;
}
.live_state.onAir:before {
    background-color: var(--color-primary);
}
/**/


/* gallery*/
.gallery__area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -8px;
}
.gallery__area .colume {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}
.gallery__area .gallery__item {
    height: 162px;
    margin-left: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}
.gallery__area .gallery__item img {
    height: 100%;
}
.gallery__area .gallery__item:hover img {
    opacity: 0.8;
}
.gallery__link {
    max-width: 329px;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: rgba(0,0,0,0.9);
    font-size: 13px;
}
.gallery__link:hover {
    text-decoration: none;
}
.gallery__link:hover .txt_sub + div {
    text-decoration: underline;
}
/**/


.search__todo__area .search__inp,
.search__todo__area .search__item,
.search__todo__area .search_support_area
{
    max-width: 822px;
    margin: 0 auto;
}
.search__todo__area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    background-color: var(--color-main);
    width: 100%;
    flex-grow: 1;
}
.search__todo__area .search__inp {
    flex-shrink: 0;
    width: 100%;
    height: 120px;
}
.search__todo__area .search__inp input {
    width: 100%;
    height: 100%;
    font-size: 34px;
    font-weight: 700;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid var(--border-color);
    padding-left: 0;
}
.search__todo__area .search__inp .searchBtn {
    width: 52px;
    height: 100%;
}
.search__todo__area .search__item {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    width: 100%;
}
.search__todo__area .search__item .order__wrap {
    flex-grow: 1;
    width: 100%;
}

.todo__inner,
.hash__inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    padding-bottom: 50px;
    height: 100%;
}
.todo__inner .txt_sub,
.hash__inner .txt_sub {
    width: 100%;
    flex-shrink: 0;
    padding-bottom: 12px;
}

.todo__list,
.hash__list {
    flex: auto;
    width: 100%;
    max-height: calc(100%);
}
.todo__list li,
.hash__list li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 12px 0;
    cursor: pointer;
}
.todo__list li:hover,
.hash__list li:hover {
    text-decoration: underline;
    text-underline-position: under;
}
.todo__list li button {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-left: 6px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.todo__list li button:before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(/public/image/asset/icon/ic_delete.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.scroll__wrap .todo__list,
.scroll__wrap .hash__list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 100%;
}

.search__notfound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 100%;
    padding-top: 74px;
}
.search__notfound .icon {
    margin-bottom: 24px;
}

.search__result {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 130px;
}
.search__result .resultTxt {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 18px;
}
.search__result .keyword {
    font-weight: 700;
    color: var(--color-primary);
    margin-right: 5px;
}

.search__conetnt {
    width: 100%;
}

/* ìžë™ì™„ì„± */
.sug_auto_keyword {
    width: 100%;
    padding: 24px 0;
}
.sug_auto_keyword li {
    padding: 8px 0;
    color: var(--color-nt-600);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}
.sug_auto_keyword li > div {
}

.sug_auto_keyword li > div:hover {

}
.sug_auto_keyword span {
    color: #fff;
}
/**/

/* scroll wrap */
.scroll__wrap {
    flex-grow: 1;
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
}
.scroll__wrap .scroll__inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.scroll__wrap::-webkit-scrollbar {
    width: 20px;
    background-color: transparent;
}
.scroll__wrap::-webkit-scrollbar-thumb {
    background-color: var(--color-gray);
    border: 7px solid var(--color-main);
    border-radius: 10px;
    cursor: pointer;
}
.scroll__wrap::-webkit-scrollbar-track {
    background-color: transparent;
}

.scroll__hide::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background-color: transparent;
}
.scroll__hide::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0px;
    cursor: pointer;
}
.scroll__hide::-webkit-scrollbar-track {
    background-color: transparent;
}
/**/

/* loading */
.loading__area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*pointer-events: none;*/
}
.loading_ani {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.loading__progress {
    position: relative;
    width: 64px;
    height: 4px;
    background-color: #fff;
    margin: 8px 0;
}
.loading__progress .bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--color-primary);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
/**/

/* form */
.field__twin,
.field__inp {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.field__inp > input,
.field__inp > .select__wrap {
    flex-grow: 1;
    width: 100%;
}
.field__inp > input:disabled {
    color: var(--color-nt-500);
}
.field__inp > .btn {
    flex-shrink: 0;
    width: 140px;
    height: auto;
    padding: 0 10px;
    text-align: center;
    margin-left: 12px;
}
.field__twin .field__inp {
    flex-grow: 1;
}
.field__twin .field__inp + .field__inp {
    margin-left: 8px;
}

.field__inp .slash {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
}
.field__inp .slash + input {
    max-width: 120px;
}

.field__btn {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.field__btn .btn {
    flex-shrink: inherit;
    flex-grow: 1;
    width: 100%;
    height: 64px;
    font-size: 16px;
    font-weight: 400;
}
.field__btn .btn + .btn {
    margin-left: 16px;
}
.inp_id,
.inp_pwd {
    padding-left: 60px;
    background-position: left 20px center;
    background-repeat: no-repeat;
    background-size: 24px;
}
.inp_id {
    background-image: url(/public/image/asset/icon/inp_id.svg);
}
.inp_pwd {
    background-image: url(/public/image/asset/icon/inp_pwd.svg);
}

.focus-item {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    margin-right: 22px;
    display: none;
}
.field__inp.focus .focus-item {
    display: block;
}

.field__inp.verify > input {
    border-color: var(--color-primary) !important;
}

.inp-vaild-alert {
    color: var(--color-primary);
    margin-bottom: 24px;
    margin-top: 12px;
}
.inp-vaild-alert .true {
    color: var(--color-green);
}

.btn-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.sign-form-area {
    width: 100%;
    max-width: 492px;
    padding: 48px;
    margin: 0 auto;
}
.sign-form-area .txt_head2 {
    text-align: center;
}
.sign-form-area .auto-sign {
    margin: 20px 0;
}
.sign-form-area .find__accound {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    color: var(--color-nt-500);
}
.sign-form-area .find__accound a {
    position: relative;
    text-align: center;
    width: 112px;
}
.sign-form-area .find__accound a + a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    width: 1px;
    height: 12px;
    background-color: var(--color-gray);
}
.find_alert {
    text-align: center;
}



/* ë©”ì¼ ì¸ì¦ */
.field__code {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.field__code input {
    width: 56px;
    padding: 0 15px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
}
.field__code input.non {
    background-image: url(/public/image/asset/icon/inp_code.svg);
    background-repeat: no-repeat;
    background-position: center;
}
.field__code input.non:focus {
    background-image: none;
}
.code_laert {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    text-align: center;
}
.code_laert a {
    text-decoration: underline;
    text-underline-position: under;
}
/**/

/* íšŒì› íƒˆí‡´ */
.signout_checkup {
    padding: 22px;
    border: 1px solid var(--color-gray);
    border-right: 8px;
    font-size: 18px;
}
.signout_checkup li {
    padding: 10px;
}
/*    */

/* ë‚´ì •ë³´ */
.order__item.gnb {
    flex-grow: inherit;
    flex-shrink: 0;
    width: 320px;
}
.page__gnb__menu {
    position: sticky;
    top: 150px;
}
.page__gnb__menu li {
    font-size: 18px;
    margin-bottom: 33px;
}
.page__gnb__menu a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-nt-500);
    text-decoration: none;
}
.page__gnb__menu a .icon {
    margin-right: 10px;
}
.page__gnb__menu .active {
    font-weight: 700;
    color: #fff;
}

.table_myinfo { border-top: 0; }
.table_myinfo .row dl dt {
    padding-left: 0;
}
.table_myinfo .btn {
    width: auto;
    min-width: inherit;
    padding: 0 16px;
}

.content_controll {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 40px;
}
.content_controll > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.content_controll .btn {
    width: 144px;
    height: 64px;
    font-weight: 400;
}
.content_controll .btn + .btn {
    margin-left: 16px;
}
/**/


/* ì´ìš©ì•½ê´€ */
.terms__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: nowrap;
}
.terms__list li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    color: var(--color-nt-600);
    padding: 0 24px;
}
.terms__list li a {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 9px;
    text-decoration: none;
}
.terms__list li + li:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 1px;
    height: 20px;
    margin-top: 9px;
    background-color: var(--color-nt-700);
}
.terms__list li:first-child {
    padding-left: 0;
}
.terms__list li.active a {
    color: #fff;
    border-bottom: 1px solid #fff;
}
.terms__text__area .txt_head4 {

}
.terms__text__area .txt_head5,
.terms__text__area .txt_bold {
    color: var(--color-nt-400);
}
.terms__text__area .txt_pre {
    color: var(--color-nt-400);
}
/**/



/* filter layer */
.filter-md-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
    display: none;
}
.filter-md-wrap .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.92);
    z-index: 1;
}
.filter-md-wrap .content-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1248px;
    padding: 100px 0;
    margin: 0 auto;
    z-index: 10;
}
.filter-md-wrap .content-area .close {
    position: absolute;
    top: 100px;
    right: 0;
    margin-right: -56px;
    cursor: pointer;
}
.filter-md-wrap .content-area .close .icon {
    width: 40px;
    height: 40px;

}
.filter_controll {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 120px;
    padding: 32px 48px;
    background-color: var(--color-table-bg);
}
.filter_controll .btn,
.filter_controll .checkbox {
    height: 100%;
    margin-left: 16px;
}
.filter_controll .checkbox {
    padding: 0 15px;
}


.searchFilter_wrap {
    width: 100%;
    padding-bottom: 48px;
}
.filter_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
}
.filter_head .filter_drop {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/public/image/asset/icon/ic_drop_down.svg);
    cursor: pointer;
}
.filter_head .filter_drop.active {
    background-image: url(/public/image/asset/icon/ic_drop_up.svg);
}
.filter_tag_area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
}
.filter_tag_area .title {
    flex-shrink: 0;
    font-size: 24px;
    font-weight: 700;
    margin-right: 32px;
    margin-bottom: 8px;
}
.filter_tag_area .tagList {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.filter_tag_area .tagList .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    padding-left: 16px;
    padding-right: 10px;
    border: 1px solid #fff;
    border-radius: 30px;
    margin-right: 8px;
    margin-bottom: 8px;
}
.filter_tag_area .tagList .item .deleteBtn {
    display: flex;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background-image: url(/public/image/asset/icon/tag_delete.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 6px;
}

.filter_options {
    border-top: 1px solid var(--border-color);
    padding: 16px 0;
}
.filter_options .select__wrap {
    max-width: 550px;
}
.filter_options .title {
    font-size: 16px;
    font-weight: 700;
    margin: 8px 0;
}
.filter_options .item__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.filter_options .item__list .option {
    margin: 8px 38px;
    margin-left: 0px;
    font-size: 16px;
    min-width: 42px;
    color: var(--color-nt-500);
    cursor: pointer;
}
.filter_options .item__list .option.active {
    color: var(--color-primary);
    font-weight: 700;
}

.search-md-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
}
.search-md-wrap .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.92);
    z-index: 1;
}
.search-md-wrap .content-area {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.search-md-wrap .modal-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 120px;
    font-size: 18px;
    font-weight: 700;
}
.search-md-wrap .modal-body {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    width: 100%;
    max-height: 100%;
    max-width: 1248px;
    padding-top: 120px;
    margin: 0 auto;
    overflow-y: scroll;
}
.search-md-wrap .close {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    width: 50px;
    height: 50px;
    margin: 35px;
    margin-right: 10%;
    cursor: pointer;
}

/* galley */
.gallery-md-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
    display: none;
}
.gallery-md-wrap .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.92);
    z-index: 1;
}
.gallery-md-wrap .content-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 822px;
    padding: 50px 0;
    margin: 0 auto;
    z-index: 10;
}
.gallery-md-wrap .content-area .close {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -56px;
    cursor: pointer;
}
.gallery-md-wrap .content-area .close .icon {
    width: 40px;
    height: 40px;
}
.gallery-md-wrap .center_area {
    position: relative;
    padding: 48px 0;
    width: 100%;
}

.galley_silde {
    padding: 0 48px;
    position: relative;
    overflow: hidden;
}
.galley_silde img {
    width: 100%;
    /*max-height: 405px;*/
}
.galley_silde .swiper-button-next,
.galley_silde .swiper-button-prev{
    width: 48px;
    height: 100%;
    background-size: 16px;
    top: 0;
}
.galley__info {
    padding: 0 48px;
    font-size: 16px;
}
.galley__info .btn {
    font-size: 14px;
    padding: 0 12px;
    height: 32px;
}
.galley__info .icon:before {
    width: 16px;
    height: 16px;
}
.galley__info .btn .icon + span {
    margin-right: 6px;
}
.galley__info .txt_sub {
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-right: 24px;
}

.galley__info .galley__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-gray);
    margin-bottom: 24px;
    padding: 24px 0;
}
.galley__info .galley__head  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.galley__info .galley__head .txt_sub {
    margin-bottom: 0;
}
.galley__info .galley__details {
    margin-bottom: 32px;
}
.galley__info .hashtag {
    margin-top: 8px;
    font-size: 14px;
}
.galley__info .file_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.galley__info .file_info > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 40px;
}
.galley__info .file_info .btn {
    margin-left: 12px;
}

@media(max-width: 1279px) {
    .order__wrap {
        flex-flow: column;
    }
    .order__sub {
        width: 100%;
        margin-left: 0;
    }
    .order__twin {
        flex-flow: wrap;
    }
}


@media(max-width: 1024px) {
    body {
        /*font-size: 14px;*/
    }

    /* container */
    .container__ > .inner {
        padding-top: 16px;
        padding-bottom: 64px;
    }

    /* margin */
    .mb_120 { margin-bottom: 54px; }
    .mt_64 { margin-top: 32px; }
    .mt_48 { margin-top: 24px; }
    .mt_32 { margin-top: 16px; }
    .mt_24 { margin-top: 12px; }
    .mt_16 { margin-top: 8px; }

    .mb_120 { margin-bottom: 54px; }
    .mb_64 { margin-bottom: 32px; }
    .mb_48 { margin-bottom: 24px; }
    .mb_32 { margin-bottom: 16px; }
    .mb_28 { margin-bottom: 14px; }
    .mb_24 { margin-bottom: 12px; }
    .mb_16 { margin-bottom: 8px; }

    /*    .ml_20 { margin-left: 20px; }
        .ml_15 { margin-left: 15px; }
        .ml_10 { margin-left: 10px; }*/
    .ml_4 {
        margin-right: 4px;
    }

    .txt_display {
        font-size: 72px;
        font-weight: 700;
    }
    .txt_display2 {
        font-size: 64px;
        font-weight: 700;
    }
    .txt_head {
        font-size: 48px;
        font-weight: 700;
    }
    .txt_head2 {
        font-size: 34px;
        font-weight: 700;
    }
    .txt_head3 {
        font-size: 16px;
        font-weight: 700;
    }
    .txt_head4 {
        font-size: 24px;
        font-weight: 700;
    }
    .txt_head5 {
        font-size: 20px;
        font-weight: 700;
    }
    .txt_head6 {
        font-size: 16px;
        font-weight: 700;
    }
    .txt_head7 {
        font-size: 14px;
        font-weight: 700;
    }

    .txt_live {
        padding: 2px 10px;
        font-size: 12px;
    }

    /* header */
    .header__ { height: 56px; }
    .header__ > .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .hd__gng__area {
        padding: 10px 0;
    }
    .hd__gng__area .logo {
        height: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .hd__gng__area .logo img {
        width: auto;
        height: 100%;
    }
    .hd__tool__area {
        margin-right: 0;
    }
    .hd__tool__area a {
        font-size: 16px;
        padding: 0;
        margin-left: 20px;
    }

    .hd__gng__area .gnb__menu {
        display: none;
    }

    /* container */
    .header__ + .container__ {
        padding-top: 56px;
    }
    .header__.black + .container__ {
        padding-top: 0;
    }

    /* footer */
    .footer__ .inner {
        flex-flow: column;
        align-items: flex-start;
    }
    .ft__gng__area .gnb__menu {
        width: 100%;
    }
    .ft__gng__area ul {
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 8px 0;
    }
    .ft__gng__area li {
        flex-grow: 1;
        padding: 0 12px;
    }
    .text_copyright {
        padding-bottom: 24px;
    }

    /* edit tool*/
    .edit__tool .btn {
        flex-grow: 1;
    }


    /* div table */
    .div__table__area {
        display: flex;
        justify-content: flex-start;
        flex-flow: column;
    }
    .div__table__area .row {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-flow: column;
        width: 100%;
    }
    .div__table__area .row dl {

    }
    .div__table__area .row dl dt {
        width: 130px;
        word-break: keep-all;
    }
    .div__table__area .row dl dt.th_sd {
        width: 96px;
    }
    .div__table__area .row dl dt + dd {
        padding-left: 20px;
    }
    .div__table__area .row dl dd {
        flex-wrap: wrap;
        overflow: hidden;
    }
    .div__table__area .row dl dd > input {
        max-width: 100%;
    }
    .div__table__area .row dl dd input + .btn {
        flex-shrink: 0;
        margin: 4px;
    }

    .div__table__area.table__row__sub .row dl + dl dt { margin-left: 0; }


    /* mobile trans table */


    /**/


    /* content */
    .head_title .txt_sub {
        margin-bottom: 0px;
        margin-left: 5px;
    }
    .head__tool .btn-revers {
        display: none;
    }
    .head__tool .select__wrap,
    .head__tool select {
        height: 40px;
        padding-left: 4px;
        background-position: right 0px center;
        min-width: inherit;
    }

    .content_head .txt_head2 {
        font-size: 24px;
    }

    /* content tab*/
    .content_tab li {
        flex-grow: 1;
        text-align: center;
        padding: 0 12px;
    }
    .content_tab li .tab_title {
        font-size: 18px;
    }
    /**/

    /* search */
    .search__inp__area .search__inp {
        flex-grow: 1;
        max-width: inherit;
    }
    .search__inp input {
        width: 100%;
    }

    .search_support_area {

    }

    .search__tag__area {
        flex-wrap: nowrap;
        overflow-y: scroll;
    }

    /* video */
    .video__info {
        padding-top: 12px;
        font-size: 12px;
    }
    .video__info .txt_subject {
        margin: 4px 0;
        font-size: 16px;
    }
    .video__info .txt_sub {
        font-size: 13px;
    }

    .thum_mask .circle {
        width: 57px;
        height: 57px;
        font-size: 10px;
    }
    .thum_mask .live_state {
        margin: 6px 8px;
        font-size: 10px;
    }
    .live_state:before {
        margin-right: 4px;
    }

    .game__area .block__list > ul > li {
        flex: 0 1 50%;
        max-width: calc(50% - 4px);
        margin-right: 8px;
        margin-bottom: 42px
    }
    .game__area .block__list > ul > li:nth-child(2n),
    .game__area .block__list > ul > li:nth-child(4n) {
        margin-right: 0;
    }

    .size__mb .video__card {
        align-items: center;
    }
    .size__mb .video__prev {
        width: 136px;
        padding-bottom: 77px;
        margin-right: 16px;
    }
    .size__mb .video__info .txt_subject {
        margin: 4px 0;
    }
    .game__area .block__list.size__mb > ul > li > a {
        padding: 12px 0;
    }
    /**/

    /* hash tag */
    .hash__tag__area {
        margin-bottom: 32px;
    }
    .hash__tag__area .txt_tag {
        margin-right: 8px;
        margin-bottom: 8px;
    }
    /**/

    /* tiemr input */
    .timer__inp { width: 100%; }



    /* slide */
    .slide__content__wrap.mt_48 {
        margin-top: 20px;
    }
    .pagenation-control .pagenation-wrap {
        justify-content: center;
    }
    .pagenation-control .swiper-btn-pause {
        margin-left: 15px;
    }
    .swiper-pagination-bullet {
        width: 24px;
    }
    .swiper-pagination-bullet+.swiper-pagination-bullet { margin-left: 8px; }
    .visual__slide .pagenation-control {
        position: relative;
        left: 0;
        bottom: 0;
        margin: 16px 0;
    }
    .visual__slide .pagenation-wrap {
        padding: 0;
    }
    .visual__slide .swiper-slide,
    .visual__slide .swiper-button-next,
    .visual__slide .swiper-button-prev { height: 268px; }
    .visual__slide .swiper-button-next,
    .visual__slide .swiper-button-prev {
        width: 24px;
        margin: 0 8px;
        background-size: 7.7px;
    }
    .visual__slide .txt_centerY {
        flex-flow: column;
        align-items: flex-start;
    }
    .visual__slide .txt_head5  {
        margin-left: 0;
        margin-top: 12px;
        font-size: 16px;
    }
    .visual__slide .slide_number { display: none; }
    .visual__slide .slide_txt {
        padding: 48px;
    }
    .visual__slide .slide_txt .txt_display2 {
        font-size: 24px;
        margin-top: 4px;
        margin-bottom: 16px;
    }
    .visual__slide .txt_head6 {
        font-size: 14px;
        font-weight: 400;
    }

    .onAir__slide__wrap {
        margin-top: 0;
    }
    .onAir__slide .swiper-slide {
        margin: 0;
        padding-right: 16px;
    }

    .onAir_card,
    .card__slide .video__card {
        width: 224px;
    }

    .onAir_card {
        padding: 24px;
    }
    .onAir_card .txt_head4 {
        font-size: 16px;
    }
    .onAir_card .live_state {
        float: right;
    }
    .onAir_card .live_state,
    .onAir_card .txt_sub {
        font-size: 12px;
        margin-bottom: 4px;
    }

    .card__slide .swiper-slide { padding-right: 16px; }
    .card__slide .video__info .txt_bold { font-size: 12px }
    .card__slide .video__info .txt_subject { font-size: 16px; }
    .card__slide .video__info .txt_sub { font-size: 13px; }
    .card__slide + .swiper-control { display: none; }


    /* order */
    .order__twin { flex-flow: column; }
    .order__twin .order__item { max-width: 100%; }
    .order__twin .order__item + .order__item { margin-left: 0; margin-top: 32px; }



    /* form */
    .m_hide {
        display: none;
    }
    .header__.black {
        display: none;
    }
    .sign-form-area {
        padding: 0;
        padding-top: 40px;
    }
    .sign-form-area .txt_head2 {
        line-height: 1em;
        margin-bottom: 48px;
    }

    .field__inp > .btn,
    .field__inp > .btn.confirm {
        width: 96px !important;
    }
    .field__inp .slash + input {
        width: 72px;
    }
    .field__inp .selectBox .option {
        height: 56px;
        font-size: 16px;
    }

    .field__terms {
        margin-top: 48px;
    }
    .field__btn {
        margin-top: 24px;
    }
    .field__btn .btn {
        font-size: 18px;
        font-weight: 700;
    }
    .find_alert {
        word-break: keep-all;
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 24px;
    }
    .field__code input {
        width: 48px;
        height: 48px;
    }
    .code_laert {
        margin-top: 48px;
    }
    .code_laert .txt_sub {
        margin-bottom: 16px;
    }

    .checkbox > #CK_All + label {
        color: #fff;
    }

    /* íƒˆí‡´ */
    .signout_checkup {
        padding: 16px;
    }
    .signout_checkup li {
        padding: 8px;
        font-size: 14px;
    }

    /* ì´ìš©ì•½ê´€  */
    .terms__list {
        margin-bottom: 24px;
    }
    .terms__list li {
        flex-shrink: 0;
        padding: 0 12px;
    }
    .terms__list li a { font-size: 16px; }
    .terms__list li + li:before { margin-top: 6px; height: 14px; }

    .terms__text__area .mb_48 { margin-bottom: 48px; }
    .terms__text__area .mb_16 { margin-bottom: 16px; }

    /* */
    .dateTimer_inp input {
        flex-grow: 1;
    }
    .dateTimer_inp span {
        flex-shrink: 0;
    }

    /* search*/
    .search__notfound {
        padding-top: 50px;
    }
    .search__result {
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        margin-bottom: 8px;
    }
    .search__result .resultTxt {
        justify-content: flex-start;
        font-size: 16px;
        padding: 16px;
    }
    .sug_auto_keyword {
        padding: 24px 16px;
    }

    .search__field__group {
        flex-wrap: wrap;
    }
    .search__field__group .item {
        margin-right: 0;
    }
    .search__field__group .item__gorup {
        flex-wrap: wrap;
        width: 100%;
    }
    .search__field__group .dateTimer_inp {
        flex-shrink: 0;
        width: 100%;
        max-width: inherit;
        order: 1;
        margin-bottom: 8px;
    }
    .search__field__group .dateTimer_inp input {
        min-width: auto;
    }
    .search__field__group > .btn {
        flex-shrink: 0;
        width: 100%;
        max-width: inherit;
        margin-top: 16px;
        order: 3;
    }

    .search__field__group .search__inp,
    .search__field__group .select__wrap {
        order: 2;
        min-width: inherit;
        width: calc(50% - 4px);
    }
    .search__field__group .search__inp {
        order: 3;
    }
    .search__field__group .select__wrap {
        margin-right: 8px;
    }
    .search__field__group .selectBox .option {
        height: 56px;
    }

    .search__todo__area .search__inp {
        height: 56px;
    }
    .search__todo__area .search__inp input {
        border-bottom: 1px solid #fff;
        font-size: 16px;
        font-weight: 400;
    }
    .search__todo__area .search__inp .searchBtn {
        width: 56px;
        height: 56px;
    }

    .todo__inner .txt_sub,
    .hash__inner .txt_sub {
        font-size: 13px;
    }
    .todo__list li,
    .hash__list li {
        padding: 8px 0;
    }

    /* .search-md-wrap .modal-body */
    .search-md-wrap .content-area {
        /*overflow: scroll;*/
    }
    .search-md-wrap .modal-body {
        padding-top: 0;
        overflow: hidden;
    }
    .search-md-wrap .modal-head {
        height: 56px;
    }
    .search-md-wrap .modal-head .logo {
        display: none;
    }
    .search-md-wrap .close {
        left: 0;
        margin: 0;
        width: 56px;
        height: 100%;
    }
    .search-md-wrap .close .gas-close:before {
        background-image: url(/public/image/asset/icon/ic_back.svg);
        background-size: contain;
    }
    .search-md-wrap .search__todo__area .search__inp input {
        padding-left: 56px;
    }
    .search-md-wrap .search__item .order__wrap {
        margin-top: 0;
        padding: 24px 16px;
    }
    .search-md-wrap .order__twin .order__item + .order__item {
        margin-top: 0;
    }
    .search-md-wrap .todo__inner,
    .search-md-wrap .hash__inner {
        padding-bottom: 32px;
    }
    .search-md-wrap .todo__inner .txt_sub,
    .search-md-wrap .hash__inner .txt_sub {
        font-size: 13px;
    }

    .search-md-wrap .search__conetnt {
        padding: 16px;
        padding-right: 0;
        overflow-y: scroll;
        height: calc(100vh - 120px);
    }
    .search-md-wrap .search__conetnt .head__tool {
        margin-right: 16px;
    }
    .search-md-wrap .search__conetnt .game__area .block__list {
        overflow-x: scroll;
    }
    .search-md-wrap .search__conetnt .game__area .block__list > ul {
        flex-wrap: nowrap;
    }
    .search-md-wrap .search__conetnt .game__area .block__list > ul:after {
        flex-shrink: 0;
        content: "";
        width: 1px;
        height: 1px;
    }
    .search-md-wrap .search__conetnt .game__area .block__list > ul > li,
    .search-md-wrap .search__conetnt .game__area .block__list > ul > li:nth-child(2n),
    .search-md-wrap .search__conetnt .game__area .block__list > ul > li:nth-child(4n) {
        margin-right: 16px;
        width: 208px;
        flex-shrink: 0;
    }

    .filter-md-wrap .content-area .close {
        top: 0;
        right: 0;
        margin-right: 0;
    }
    .filter-md-wrap .content-area {
        width: 100%;
        padding: 0;
        padding-top: 56px;
    }
    .filter-md-wrap .content-area .close .icon {
        width: 56px;
        height: 56px;
    }
    .filter-md-wrap .content-area .close .icon:before {
        width: 24px;
        height: 24px;
        background-size: 30px;
    }
    .filter-md-wrap .scroll__wrap .scroll__inner {
        padding-left: 16px;
    }
    .filter_head {
        align-items: flex-start;
    }
    .filter_head .filter_drop {
        height: 30px;
    }
    .filter_tag_area {
        flex-flow: column;
        align-items: flex-start;
        padding-top: 0;
    }
    .filter_tag_area .title {
        font-size: 20px;
    }
    .filter_tag_area .tagList .item {
        font-size: 14px;
    }
    .filter_options .item__list .option {
        width: calc(100% / 6);
        margin: 6px 0;
        text-align: center;
    }

    #search_league .filter_options .item__list .option {
        width: auto;
        margin-right: 20px;
    }
    #search_league .filter_options .item__list {
        margin-bottom: 32px;
    }

    .filter_controll {
        padding: 0;
        height: 56px;
        border-top: 1px solid var(--color-gray);
    }
    .filter_controll .btn,
    .filter_controll .checkbox {
        flex: 1 1 50%;
        margin: 0;
        border-radius: 0;
        font-size: 16px;
    }
    .filter_controll .checkbox {

    }
    .filter_controll #resetBtn {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        font-size: 14px;
        padding: 0 14px;
        border-radius: 30px;
        margin: 13px 16px;
        z-index: 100;
    }

    .searchFilter_wrap {
        padding-bottom: 32px;
    }

    /* ë‚´ì •ë³´ */
    .order__item.gnb {
        position: fixed;
        top: 56px;
        left: 0;
        width: 100%;
        background-color: var(--color-main);
        border-bottom: 1px solid var(--color-gray);
        z-index: 3000;
    }
    .order__item.gnb + .order__item {
        padding-top: 46px;
    }
    .order__item.gnb + .order__item .content_head {
        display: none;
    }
    .page__gnb__menu {
        top: 0;
        padding: 0 16px;
        overflow-x: scroll;
    }
    .page__gnb__menu ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
    }
    .page__gnb__menu ul:after {
        content: "";
        flex-shrink: 0;
        width: 10vw;
        height: 1px;
    }
    .page__gnb__menu ul li {
        flex-shrink: 0;
        margin-bottom: 0;
        margin-right: 16px;
    }
    .page__gnb__menu a {
        height: 56px;
        white-space: normal;
        font-size: 16px;
    }
    .page__gnb__menu a .icon {
        display: none;
    }
    .content_head {
        /*display: none;*/
        margin-bottom: 20px;
    }
    .content_head.block_abled {
        display: flex !important;
    }
    .content_head .select__wrap {
        min-width: auto;
    }
    .content_controll {
        flex-wrap: wrap;
        margin-top: 16px;
    }
    .content__tab__area {
        width: 100%;
        margin-top: 16px;
    }
    .content__tab__area .content_tab li {
        padding: 0 12px;
    }
    .content__tab__area .content_tab li .tab_title {
        font-size: 24px;
    }
    .content__tab__area .content_tab li.active .tab_title {
        display: block;
        width: 100%;
    }

    .content_controll .btn {
        flex-grow: 1;
        width: auto;
        height: 56px;
        font-size: 16px;
    }
    .content_controll .btn + .btn {
        margin-left: 8px;
    }
    .content_controll > .single {
        flex-shrink: 0;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
        width: 100%;
    }
    .content_controll > .single .btn {
        flex-grow: 0;
        width: auto;
        height: auto;
        min-width: inherit;
        justify-content: flex-start;
        padding: 0;
        background-color: transparent;
        text-decoration: underline;
        text-underline-position: under;
    }
    .content_controll > .group {
        flex-grow: 1;
    }
    .content_controll > .group .btn {
        flex-grow: 1;
    }
    .content_controll .pagenate__area {
        justify-content: center;
        margin-top: 16px;
        width: 100%;
    }


    .tableSelect {
        width: auto;
    }
    .trans_table__area {}
    .trans_table__area .table__head {
        display: none;
    }
    .trans_table__area dl {
        flex-wrap: wrap;
        position: relative;
        padding: 16px 0;
        padding-left: 40px;
    }
    .trans_table__area dd {
        padding: 0;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: auto;
    }
    .trans_table__area dd:first-child {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        padding: 0;
        margin-top: 16px;
        z-index: 10;
    }


    .notice_table dd {
        flex-shrink: 0;
        width: 100%;
    }
    .download_table dd {
        padding: 0 !important;
    }
    .download_table dd:nth-child(3) img {
        width: 100%;
    }
    .upload_table dd:nth-child(2),
    .favor2P_table dd:nth-child(2) { display: none; }

    .upload_table dd:nth-child(4),
    .upload_table dd:nth-child(5),
    .upload_table dd:nth-child(6),
    .upload_table dd:nth-child(7),
    .download_table dd:nth-child(3),
    .download_table dd:nth-child(4),
    .download_table dd:nth-child(5),
    .download_table dd:nth-child(6) { margin-bottom: 6px; }

    .notice_table dd:nth-child(2),
    .upload_table dd:nth-child(3),
    .download_table dd:nth-child(2) {
        padding: 0;
        width: 100%;
        font-size: 13px;
        justify-content: flex-start;
        color: var(--color-nt-600);
        margin-bottom: 6px;
    }

    .upload_table dd:nth-child(5),
    .upload_table dd:nth-child(8),
    .download_table dd:nth-child(5),
    .download_table dd:nth-child(8) {
        width: 100%;
    }
    .download_table dd:nth-child(5) {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
    .upload_table dd:nth-child(6) {
        flex-grow: 0;
        width: auto;
        padding-right: 16px
    }
    .upload_table dd:nth-child(0),
    .download_table dd:nth-child(7) {
        justify-content: flex-end;
    }

    .download_table dd:nth-child(8) > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .download_table dd:nth-child(8) .btn {
        margin-right: 8px;
        margin-bottom: 0;
    }

    .favor_table,
    .favor2P_table {  }

    .favor_table .table__body dl,
    .favor2P_table .table__body dl {
        border-top: 1px solid var(--color-gray);
    }

    .favor_table dd,
    .favor2P_table dd {
        padding: 0 !important;
        font-size: 13px;
    }
    .favor2P_table dd:nth-child(6),
    .favor_table dd:nth-child(7) {
        order: 1;
    }
    .favor2P_table dd:nth-child(3),
    .favor_table dd:nth-child(3) {
        order: 2;
        color: var(--color-nt-600);
    }
    .favor2P_table dd:nth-child(4),
    .favor_table dd:nth-child(4) {
        order: 3;
    }
    .favor2P_table dd:nth-child(5),
    .favor_table dd:nth-child(5) {
        order: 5;
    }
    .favor2P_table dd:nth-child(7),
    .favor_table dd:nth-child(6) {
        order: 6;
    }
    .favor2P_table dd:nth-child(8),
    .favor_table dd:nth-child(9) {
        order: 4;
        font-size: 16px;
    }
    .favor_table dd:nth-child(8) {
        order: 7;
    }

    .favor_table dd:nth-child(2),
    .favor2P_table dd:nth-child(2) {
        display: none;
    }

    .favor2P_table dd:nth-child(4),
    .favor2P_table dd:nth-child(6),
    .favor_table dd:nth-child(4),
    .favor_table dd:nth-child(7) {
        max-width: 85px;
        min-width: 85px;
    }

    .favor2P_table dd:nth-child(3),
    .favor2P_table dd:nth-child(8),
    .favor_table dd:nth-child(3),
    .favor_table dd:nth-child(9) {
        flex-grow: 0;
        max-width: calc(100vw - 163px);
        min-width: calc(100vw - 163px);
        padding-left: 16px !important;
    }

    .favor2P_table dd:nth-child(6) {
        color: var(--color-primary);
    }
    .favor2P_table dd:nth-child(3),
    .favor2P_table dd:nth-child(6),
    .favor2P_table dd:nth-child(8),
    .favor_table dd:nth-child(3),
    .favor_table dd:nth-child(4),
    .favor_table dd:nth-child(7),
    .favor_table dd:nth-child(9) {
        margin-bottom: 6px;
    }
    .favor2P_table dd:nth-child(5){
        margin-left: 85px;
        margin-right: 5px;
        flex-grow: 0;
        width: auto;
        padding-left: 16px !important;
    }

    .favor_table dd:nth-child(5),
    .favor_table dd:nth-child(6),
    .favor_table dd:nth-child(8) {
        flex-grow: 0;
        width: auto;
    }
    .favor_table dd:nth-child(5) {
        margin-left: 85px;
        flex-grow: 0;
        width: auto;
        padding-left: 16px !important;
    }
    .favor_table dd:nth-child(5):after {
        content: "-";
    }
    .favor_table dd:nth-child(8):before {
        content: "ï½œ";
    }

    .table_myinfo .row dl dt,
    .table_myinfo .row dl dd,
    .upload_form_table .row dl dt,
    .upload_form_table .row dl dd {
        padding: 8px 0;
        font-size: 16px;
    }
    .table_myinfo .row dl dt,
    .upload_form_table .row dl dt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        width: 72px;
    }
    .table_myinfo .row dl dt + dd,
    .upload_form_table .row dl dt + dd  {
        padding-left: 18px;
    }
    .table_myinfo .btn,
    .table_myinfo .row dl dd input + .btn {
        width: auto !important;
        height: 40px;
    }

    .upload_form_table .row:nth-child(2) dl {
        flex-flow: column;
    }
    .upload_form_table .row:nth-child(2) dd {
        padding: 8px 0;
    }
    .upload_form_table .row:nth-child(2) dt {
        min-height: 56px;
    }


    .file__upload .btn {
        padding: 0 16px;
    }

    /* */


    /* galley*/
    .gallery__area {
        margin-left: 0;
    }
    .gallery-md-wrap .dim {
        background-color: rgba(0,0,0,0.85);
    }
    .gallery__area .colume {
        width: calc(50% - 4px);
        flex-flow: column;
    }
    .gallery__area .colume:nth-child(2n) {
        margin-left: 8px;
    }
    .gallery__area .gallery__item {
        width: 100%;
        height: auto;
        margin: 0;
        margin-bottom: 8px;
        order: 2;
    }
    .gallery__area .gallery__item img {
        height: auto;
        width: 100%;
    }
    .gallery__area .gallery__item.order--1 {
        order: 1;
    }
    .gallery__link {
        padding: 12px;
        height: 160px;
        border: 1px solid var(--color-gray);
    }
    .gallery-md-wrap .content-area {
        align-items: flex-start;
        overflow-y: scroll;
    }
    .gallery-md-wrap .content-area .close {
        top: 0;
        right: 0;
        margin: 24px;
        z-index: 1000;
    }
    .gallery-md-wrap .content-area .close .icon:before {
        background-size: contain;
    }
    .gallery-md-wrap .content-area .close .icon {
        width: 24px;
        height: 24px;
    }
    .gallery-md-wrap .center_area {
        padding: 12px 16px;
    }
    .galley_silde,
    .galley__info {
        padding: 0 32px;
        border: 1px solid var(--color-gray);
        background-color: var(--color-main);
    }
    .galley_silde {
        padding-top: 35px;
        border-bottom: 0;
        border-radius: 8px 8px 0 0;
    }
    .galley__info {
        padding-bottom: 35px;
        border-top: 0;
        border-radius: 0 0 8px 8px;
    }
    .galley_silde .swiper-button-next,
    .galley_silde .swiper-button-prev {
        width: 32px;
        display: none;
    }

    .galley__info .galley__head {
        padding: 12px 0;
        margin-bottom: 16px;
    }
    .galley__info .file_info {
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .galley__info .file_info > div {
        width: 100%;
        justify-content: space-between;
        margin-right: 0;
    }
    .galley__info .file_info > div + div {
        margin-top: 8px;
    }

}