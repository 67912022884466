:root {

}

.icon {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon:before {
	content: "";
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto;
	width: 24px;
	height: 24px;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}

.gas-plus:before { background-image: url(../img/asset/icon/ic_plus.svg); width: 9px; }
.gas-minus:before  { background-image: url(../img/asset/icon/ic_minus.svg); width: 9px; }

.gas-play:before { background-image: url(../img/asset/icon/ic_play.svg); }
.gas-pause:before { background-image: url(../img/asset/icon/ic_pause.svg); }

.gas-close:before { background-image: url(../img/asset/icon/ic_close.svg); }
.gas-close-big:before { background-image: url(../img/asset/icon/ic_close_big.svg); }
.gas-info-big:before { background-image: url(../img/asset/icon/ic_info_big.svg); width: 62px; height: 62px; }
.gas-reset:before { background-image: url(../img/asset/icon/ic_reset.svg); }


.gas-download:before { background-image: url(../img/asset/icon/ic_download.svg); }
.gas-upload:before { background-image: url(../img/asset/icon/ic_upload.svg); }
.gas-search:before { background-image: url(../img/asset/icon/ic_search_md.svg); }
.gas-search-big:before { background-image: url(../img/asset/icon/ic_search_big.svg); width: 52px; height: 52px; }
.gas-notice:before { background-image: url(../img/asset/icon/ic_notice.svg); }
.gas-del:before { background-image: url(../img/asset/icon/ic_delete.svg); }
.gas-del-circle:before { background-image: url(../img/asset/icon/ic_delete_circle.svg); }
.gas-like:before { background-image: url(../img/asset/icon/ic_like.svg); }
.gas-timer:before { background-image: url(../img/asset/icon/ic_timer.svg); }
.gas-timer2:before { background-image: url(../img/asset/icon/ic_timer2.svg); }

.gas-block:before { background-image: url(../img/asset/icon/ic_block_list.svg); }
.gas-buger:before { background-image: url(../img/asset/icon/ic_buger.svg); }
.active > .gas-block:before { background-image: url(../img/asset/icon/ic_block_list_active.svg); }
.active > .gas-buger:before { background-image: url(../img/asset/icon/ic_buger_active.svg); }


.gas-first:before,
.gas-last:before,
.gas-prev:before,
.gas-next:before {
	background-size: auto;
}

.gas-m-info:before { background-image: url(../img/asset/icon/ic_m_info.svg); }
.gas-m-notice:before { background-image: url(../img/asset/icon/ic_m_notice.svg); }
.gas-m-upload:before { background-image: url(../img/asset/icon/ic_m_upload.svg); }
.gas-m-download:before { background-image: url(../img/asset/icon/ic_m_download.svg); }
.gas-m-like:before { background-image: url(../img/asset/icon/ic_m_like.svg); }
.gas-m-logout:before { background-image: url(../img/asset/icon/ic_m_logout.svg); }

.active > .gas-m-info:before { background-image: url(../img/asset/icon/ic_m_info_active.svg); }
.active > .gas-m-notice:before { background-image: url(../img/asset/icon/ic_m_notice_active.svg); }
.active > .gas-m-upload:before { background-image: url(../img/asset/icon/ic_m_upload_active.svg); }
.active > .gas-m-download:before { background-image: url(../img/asset/icon/ic_m_download_active.svg); }
.active > .gas-m-like:before { background-image: url(../img/asset/icon/ic_m_like_active.svg); }
/*.active > .gas-m-logout:before { background-image: url(../img/asset/icon/ic_m_logout_active.svg); }*/


.gas-first:before { background-image: url(../img/asset/icon/page_first.svg);}
.gas-last:before { background-image: url(../img/asset/icon/page_last.svg); }
.gas-prev:before { background-image: url(../img/asset/icon/arrow_prev.svg); }
.gas-next:before { background-image: url(../img/asset/icon/arrow_next.svg); }


.gas-confirm:before { background-image: url(../img/asset/icon/check_cr_success.svg); }
.gas-delete-form:before { background-image: url(../img/asset/icon/ic_del_form.svg); }
.gas-trans-form:before { background-image: url(../img/asset/icon/ic_trans_form.svg); }
.active > .gas-trans-form:before { background-image: url(../img/asset/icon/ic_trans_form_active.svg); }


@media(max-width: 1024px) {
	.gas-search-big:before {
		width: 24px;
		height: 24px;
		background-size: 30px;
	}
}

