@tailwind base;
@tailwind components;
@tailwind utilities;

.modal-resign {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    background: var(--color-main);
    padding: 32px 24px 24px;
    z-index: 3000;
}

.br-0 {
    border-right: 1px solid var(--color-gray);
}

.filter_options.expanded {
    height: 100%;
    opacity: 1;
}

.filter_options {
    opacity: 0;
    display: block;
    transition: height 0.3s ease, opacity 0.3s ease;
    height: 0;
    overflow: hidden;
}

img::selection {
    color: transparent;
    background: transparent;
}

.container__ {
    min-height: calc(100vh - var(--header-height));
}
.content_head {
    align-items: start;
}

.video__info .txt_subject.ellipsis-line-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.search-md-wrap .content-area {
    background: var(--color-main);
}
.search-md-wrap .modal-head {
    z-index: 1;
    background: var(--color-main);
}

.video__info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    padding-top: 8px;
    font-size: 14px;
}
.video__info .txt_subject {
    font-size: 18px;
    margin-top: 4px;
    margin-bottom: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.video__card .txt_subject.time {
    min-width: 60px;
    max-width: 60px;
    white-space: pre-line;
}

.video__card div.txt_sub span + span {
    border-left: 2px solid var(--color-gray);
    margin-left: 10px;
    padding-left: 10px;
}

.video_placeholder {
    aspect-ratio: 16/9;
    background-image: url(/public/image/thum/player_blank_gray.svg);
    background-size: cover;
}

.home_live_thumb {
    min-width: 315px;
    aspect-ratio: 16/9;
}

.onAir__slide__wrap .swiper-slide {
    width: min-content;
    padding: 0px 16px;
}

.attach_list dd div {
    display: flex;
    justify-content: space-between;
}

/*.onAir__slide__wrap .swiper-wrapper .swiper-slide{*/
/*    width: min-content;*/
/*    margin-right: 16px;*/
/*}*/

@media (max-width: 1024px) {
    .home_live_thumb {
        min-width: 160px;
        aspect-ratio: 16/9;
    }
}

.div__table__area .row dl span {
    text-align: left;
}

.game__area .block__list.size__mb > ul > li.active {
    background-color: var(--color-gray);
}

.size__mb .th_info .txt_subject {
    font-size: 15px;
    margin-bottom: 1px;
    height: unset;
}

.size__mb .video__info .txt_subject {
    font-size: 15px;
    margin-bottom: 1px;
    height: unset;
}

/** 탭 스타일 **/
/** 약관 탭**/
.terms__list li.react-tabs__tab {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 9px;
    text-decoration: none;
}

.terms__list li.react-tabs__tab.react-tabs__tab--selected {
    color: #fff;
    border-bottom: 1px solid #fff;
}

/** 영상 내 경기기록, 관련영상 탭 **/
.content_tab li.react-tabs__tab--selected .tab_title {
    color: #fff;
    border-bottom: 2px solid #fff;
}

.react-tabs__tab-panel:not(.react-tabs__tab-panel--selected) {
    /* forceRenderTabPanel 사용시 필요 https://github.com/reactjs/react-tabs/issues/294#issuecomment-669142441*/
    display: none;
}

.react-tabs__tab--selected .btn-revers {
    background-color: #fff !important;
    color: var(--background-main) !important;
    text-decoration-color: var(--background-main) !important;
}

.react-tabs__tab--selected .btn-revers i {
    /*background-color: #fff !important;*/
    color: var(--background-main) !important;
    /*text-decoration-color: var(--background-main) !important;*/
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.react-tabs__tab-panel--selected {
    display: block;
    animation-name: fadeIn;
    animation-duration: 250ms;
    animation-timing-function: linear;
}


.game__area {
    min-height: 730px;
}

.game__area .block__list {
    height: 1px; /* height 상속 문제 해소*/
    min-height: 680px;
}

.search__notfound {
    padding-top: unset;
}


.pagenate__area ul li {
    cursor: pointer;
}

.order__wrap {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 12px;
}

.order__sub {
    flex-grow: 0;
    flex-shrink: 0;
    width: 500px;
    margin-left: 32px;
}


@media(max-width: 1279px) {
    .order__sub {
        width: 100%;
        margin-left: 0;
    }
}


.div__table__area .row dl span {
    text-align: left;
}

.card__slide .video__card {
    width: 280px;
}

.card__slide .video__info .txt_subject {
    font-size: 18px;
}

/**
    메뉴
 */
.mobile___gnb__wrap .active .dim {
    width: 100vw;
}

.m_depth a {
    padding-left: 0;
}

.m_gnb_sub {
    padding-left: 8px;
}

.m_gnb_sub.drop.txt_primary {
    color: var(--color-primary);
}

.m_depth_sub.drop_item li.active {
    color: var(--color-primary);
}

.m_depth_sub.drop_item a {
    padding-left: 24px;
}


/**
    메뉴 끝
 */

.favor_table:not(.data-table) dd:nth-child(8):before {
    content: "｜";
}

.color_checked {
    color: rgba(22, 141, 38, 1);
}


.code_laert button {
    text-decoration: underline;
    text-underline-position: under;
}

.close .gas-close:before {
    background-image: url(/public/image/asset/icon/ic_back.svg);
    background-size: contain;
}

.galley_silde img {
    max-height: 400px;
    object-fit: contain;
}


/**
KFAPlayer 스타일 수정
 */

.KFAPlayer-container .video-js .vjs-control.vjs-volume-panel .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level:before {
    top: -0.7em !important;
}

.KFAPlayer-container.KFA-floating-mode {
    width: 400px;
}

.content__wrap .KFA-floating-mode {
    display: none;
}

.video_overlay {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 0;
    z-index: 4;
    display: flex;
    justify-content: space-between;
}

.video_overlay button {
    width: 40px;
    height: 40px;
    display: none;
}

.video_overlay:hover button {
    display: block;
}


.whole-search-modal-portal {
    z-index: 9000;
    position: absolute;
}
.whole-search-modal-portal > * {
    z-index: 9000;
    position: relative;
}

.whole-search-modal-portal .modal-body{
    overflow: hidden;
}

.modal-whole-search-wrap .modal-dialog .content-area {
    background-color: var(--color-main);
}

.gallery-modal-portal {
    z-index: 9001;
    position: absolute;
}
.gallery-modal-portal > * {
    z-index: 9001;
    position: relative;
}


/**
select style
 */

.select_text .option {
    min-height: 56px;
    height: unset;
    display: flex;
    flex-wrap: wrap;
    /*gap: 10px;*/
    row-gap: 10px;
    padding: 10px 20px;
}

.select_option .option__divider {
    height: 5px;
    background-color: var(--background-inactive);
}

.select_text .option .multiple__text {
    display: block !important;
    width: max-content;
}

.select_option {
    max-height: 50vh;
    overflow-y: auto;
}

/**
mypage
 */


.table_myinfo .row dl dt {
    padding-left: 20px;
}

/**
pip
 */

.pip-container .KFA-controlbar-spacer{
    background-color: #000;
}

.title .option.active {
    color: var(--color-primary);
}


.hd__tool__area button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    padding: 16px;
}

.search__field__group .item__gorup.space {
    justify-content: space-between;
}

.pip-container {
    position: fixed;
    width: 400px;
    right: 100px;
    bottom: 0;
    z-index: 9900;
}

/**
banner
 */

.visual__slide .swiper-slide {
    /*height: 80vh;*/
    height: 720px;
    aspect-ratio: 1280/960;
}
.visual__slide .swiper-slide img {
    object-position: top;
}

@media (max-width: 1800px) {
    .visual__slide .swiper-slide {
        height: 80vh;
        max-height: 640px;
        aspect-ratio: 1280/960;
    }
}

@media (max-width: 1024px) {
    .visual__slide .swiper-slide {
        height: 80vh;
        max-height: 400px;
        aspect-ratio: 1280/960;
    }
}

@media (max-width: 800px) {
    .visual__slide .swiper-slide {
        height: 80vh;
        max-height: 268px;
        aspect-ratio: 1280/960;
    }
}

/**
live preview
 */

.live_preview_button .dim{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.live_preview_button {
    opacity: 0;
}

.live_preview_button:hover {
    opacity: 1;
    transition: opacity 0.3s;
}


.react-time-picker__inputGroup__input {
    height: 100% !important;
    padding: 0 1px !important;
}

.timer__inp .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em !important;
    padding: 0 1px !important;
    padding-left: calc(1px + 0.54em) !important;
}

.react-time-picker__wrapper {
    padding: 4px 5px!important;
    border: thin solid var(--color-gray) !important;
}

.vjs-fluid {
    padding-top: 56.25% !important;
}

dd span {
    word-break: break-word;
}

.image_card {
    width: 12.5%;
    display: inline-block;
    padding: 10px;
}

@media (max-width: 1440px) {
    .image_card {
        width: 20%;
    }
}

@media (max-width: 1024px) {
    .image_card {
        width: 25%;
    }
}

@media (max-width: 768px) {
    .image_card {
        width: 50%;
    }
}

.search__field__group .item__gorup.items-center {
    align-items: center !important;
}

/**
badge
 */

/**.icon-blue {color: #0088cc}*/
/**.icon-grey {color: grey}*/
/*i {*/
/*    width:100px;*/
/*    text-align:center;*/
/*    vertical-align:middle;*/
/*    position: relative;*/
/*}*/
.badge:after{
    border-radius: 50%;
    position: absolute;
    background: #0172CB;
    width:16px;
    height:16px;
    top:-4px;
    right:-4px;

    content:attr(data-count);
    /*font-weight: 700;*/
    font-size: 4px;
    line-height: 16px;
    text-align: center;
    font-style: normal;
    /*line-height: 2rem;;*/
    color:white;
    /*border:1px solid blue;*/
}

.file__upload .upload__inp input + label {
    height: unset !important;
    min-height: 40px !important;
}

.visual__slide .pagenation-control{
    pointer-events: none;
}

.pagenation-control .swiper-btn-pause {
    pointer-events: all;
}




/**
갤러리 헤더
 */

.head_title {
    width: max-content;
}

.search__tag__wrap{
    display: flex;
    flex-grow: 1;
}

.search__tag__wrap .search__tag{
    flex-wrap: wrap;
}

.reactive.mobile,
    /*.search__tag__wrap.mobile,*/
.content_head.mobile {
    display: none;
}


@media(max-width: 1024px) {
    .content_head.mobile {
        display: unset !important;
    }

    .reactive.mobile{
        display: unset;
    }

    .reactive.pc:not(.mobile),
    .content_head.pc:not(.mobile){
        display: none !important;
    }
}

.head_title .txt_head3 {
    max-width: 580px;
}

.password-input input {
    height: 56px;
    /*padding: 0 20px;*/
    background-color: transparent;
    border: unset;
    border-radius: unset;
    font-size: 16px;
}

.password-input {
    height: 56px;
    background-color: transparent;
    border: 1px solid var(--color-gray);
    font-size: 16px;
    border-radius: 4px;
}



input {
    padding: 0 20px;
}