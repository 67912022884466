/*.hidden {*/
/*    display: none;*/
/*    opacity: 0;*/
/*}*/

/*.appear {*/
/*    animation: fade-in 1s;*/
/*    animation-fill-mode: forwards;*/
/*    display: flex;*/
/*}*/

/*.disappear {*/
/*    animation: fade-out 1s;*/
/*    animation-fill-mode: forwards;*/
/*}*/

/*@keyframes fade-in {*/
/*    from {*/
/*        opacity: 0;*/
/*    }*/
/*    to {*/
/*        opacity: 1;*/
/*    }*/
/*}*/

@keyframes fade-out {
    0% {
        opacity: 1;
        z-index: 9999;
        /*display: block;*/
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        display: none;
        z-index: -9999;
    }
}



/*.fade { transition: all 1s; }*/

/* (B) HIDE */
.fade.hide {
    animation: fade-out 1s;
    animation-fill-mode: forwards;
    /*visibility: hidden;*/
    /*opacity: 0;*/
    /*height: 0; !* OPTIONAL *!*/
}

.loader {
    border: 8px solid #000; /* Light grey */
    border-top: 8px solid var(--color-primary); /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}