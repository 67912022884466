.selectBox {
	position: relative;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;

	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	height: 100%;
}
.selectBox .select {
	flex-grow: 1;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	color: #fff;
	cursor: pointer;

	border: 1px solid var(--color-gray);
	border-radius: 4px;
}
.selectBox > .btn {
	margin-left: 8px;
	flex-shrink: 0;
	height: 56px;
}
.selectBox .select:after {
	content: "";
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto;
	background-image: url(../img/asset/icon/spinner_down.svg);
	flex-shrink: 0;
	margin-right: 16px;
	pointer-events: none;
}
.selectBox.on .select:after {
	background-image: url(../img/asset/icon/spinner_up.svg);
}

.selectBox .select_text {
	flex-grow: 1;
	height: 100%;
}
.selectBox .select_text > input {
	width: 100%;
	height: 54px;
	border: none;
}
.selectBox .select_text .option {
	padding-right: 8px;
}
.selectBox .holder__text {
	color: var(--color-nt-600);
}

.select_option {
	list-style: none;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: var(--color-main);
	border: 1px solid var(--color-gray);
	margin-top: 4px;
	z-index: 10;
	overflow: hidden;
	display: none;
}

.select_option ul {
	padding-bottom: 10px;
}
.select_option .label {
	padding: 16px 20px;
	padding-bottom: 6px;
	color: var(--color-nt-600);
}
.select_option .selected * {
	font-weight: 700;
	background-color: #1A1B1E;
}
/*.select_option .selected .option {*/
/*	background-color: var(--background-content);*/
/*}*/


.select_option.select__scroll {
	max-height: 240px;
}
.select_option.select__scroll .label {
	width: 100%;
}
.select_option.select__scroll ul {
	max-height: calc(240px - 40px);
	overflow-y: scroll;
}
.selectBox .select_option.select__scroll .option {
	height: 36px;
}

.select_option.select__scroll ul::-webkit-scrollbar {
	width: 20px;
	background-color: transparent;
}
.select_option.select__scroll ul::-webkit-scrollbar-thumb {
	background-color: var(--color-gray);
	border: 7px solid var(--color-main);
	border-radius: 10px;
	cursor: pointer;
}
.select_option.select__scroll ul::-webkit-scrollbar-track {
	background-color: transparent;
}

.selectBox .option {
	height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid var(--color-gray);
	padding: 0 20px;
	border-right: 4px;
}
.selectBox .option:first-child {
	border-top: 0;
}
@media (max-width: 1024px) {
	.head__tool .select__wrap, .head__tool select {
		height: unset !important;
	}
}

.select_option .option:hover {
	/*background-color: var(--color-active);	*/
	background-color: #1A1B1E;
}
.selectBox .option span {
	line-height: 1.2em;
}
.selectBox .option .multiple__text + .multiple__text {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	flex-wrap: wrap;
}
.selectBox .option .multiple__text + .multiple__text:before {
	content: ",";
	margin-right: 0.4em;
}
.selectBox .option .symbol {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-right: 12px;
}
.selectBox .option .text {
	line-height: 1.2em;
}
.option_text,
.option_text_sub {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	line-height: 1.2em;
}

.option_text .deleteBtn {
	width: 24px;
	height: 24px;
	background-image: url(../img/asset/icon/ic_delete_w.svg);
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.selectBox.on {}
.selectBox.on .select {

}
.selectBox.on .select_option {
	display: block;
}



/* multiple */

.selectBox[data-multiple="true"] {}
.selectBox[data-multiple="true"] {}
.selectBox[data-multiple="true"] .option {
	justify-content: flex-start;
}
.selectBox[data-multiple="true"] .option:before {
	content: "";
	width: 24px;
	height: 24px;
	margin-right: 12px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url(../img/asset/icon/check_sq_false.svg);
}

.selectBox[data-multiple="true"] .select_text .option:before {
	display: none;
}

.selectBox[data-multiple="true"] .selected .option:before {
	background-image: url(../img/asset/icon/check_sq_true.svg);
}

.selectBox .checkbox {
	height: 56px;
	display: flex;
	/*justify-content: space-between;*/
	align-items: center;
	border-top: 1px solid var(--color-gray);
	padding: 0 20px;
	border-right: 4px;
}

.selectBox .checkbox:before {
	content: "";
	width: 24px;
	height: 24px;
	margin-right: 12px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url(../img/asset/icon/check_sq_false.svg);
}

.selectBox .checked .checkbox:before {
	background-image: url(../img/asset/icon/check_sq_true.svg);
}

@media(max-width: 1024px){
	.selectBox {
		font-size: 14px;
	}
	.selectBox .select:after {
		margin-right: 8px;
	}
	.selectBox .option {
		height: 40px;
	}
	.selectBox .checkbox {
		height: 40px;
	}
}