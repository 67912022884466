
$mq-xsmall: 640px;
$mq-small: 768px;
$mq-med: 1024px;
$mq-large: 1440px;
$mq-xlarge: 1840px;

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
}

figure {
  margin: 0;
}

.absolute-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  height: 100%;
  width: 100%;

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  transition: transform 300ms ease;
}

.css-grid-desc {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}

.css-grid {
  display: block;

  &:after {
    content: "";

    display: table;
    clear: both;
  }

  @supports (display: grid) {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr;
    width: 100%;

    &:after {
      content: none;
    }

    @media (min-width: $mq-xsmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $mq-med) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $mq-xlarge) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  & > article {
    float: left;
    width: 100%;

    @media (min-width: $mq-xsmall) {
      width: 50%;
    }

    @media (min-width: $mq-xsmall) and (max-width: ($mq-small - 1px)) {

      &:nth-child(17n + 1) {
        width: 100%;
      }
    }

    @media (min-width: $mq-med) {
      width: 25%;

      &:nth-child(34n + 1) {
        width: 50%;
      }
    }

    @media (min-width: $mq-med) and (max-width: ($mq-xlarge - 1px)) {

      &:nth-child(34n + 20) {
        float: right;
        width: 50%;
      }
    }

    @media (min-width: $mq-xlarge) {
      width: 20%;

      &:nth-child(34n + 1) {
        width: 40%;
      }

      &:nth-child(34n + 21) {
        float: right;
        width: 40%;
      }
    }

    @supports (display: grid) {
      float: none;
      width: auto;

      @media (min-width: $mq-xsmall) and (max-width: ($mq-small - 1px)) {

        &:nth-child(17n + 1) {
          grid-column-end: span 2;
          grid-row-end: span 2;
        }
      }

      @media (min-width: $mq-med) {

        &:nth-child(34n + 1) {
          grid-column-end: span 2;
          grid-row-end: span 2;
          width: auto;
        }
      }

      @media (min-width: $mq-med) and (max-width: ($mq-xlarge - 1px)) {

        &:nth-child(34n + 20) {
          grid-column-end: span 2;
          grid-row-end: span 2;
          width: auto;
        }
      }

      @media (min-width: $mq-xlarge) {

        &:nth-child(34n + 1) {
          width: auto;
        }

        &:nth-child(34n + 21) {
          grid-column-end: span 2;
          grid-row-end: span 2;
          width: auto;
        }
      }
    }
  }

  &__link {
    position: relative;

    display: block;

    overflow: hidden;

    cursor: pointer;

    &:before,
    &:after {
      content: "";
    }

    &:before {
      display: block;
      padding-top: 100%;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      background-color: #080808;
      opacity: 0;
      transition: opacity 300ms ease;
    }

    &:hover {

      &:after {
        opacity: 0.3;
      }

      .css-grid-desc {
        display: flex;
      }

      .absolute-bg {
        transform: scale(1.1);
      }
    }
  }
}
