
/*body {background-color: #efefef; flex-direction: column; align-items: stretch; justify-content: space-between;}*/
.kfa-integrate * {
    letter-spacing: -0.05em;
    word-break: keep-all;
    font-family: 'KFAGothic', sans-serif;
    line-height: inherit;
    color: #000;
}

.kfa-integrate .wrapper > * {
    position: relative;
    width: 100%;
    max-width: 82.5rem;
    margin: 0 auto;
}

.kfa-integrate a {
    text-decoration: underline;
    text-underline-position: under;
}

.kfa-integrate a:hover {
    text-decoration: underline;
    text-underline-position: under;
}

.login_sec {position: relative; width: 100%; height: 100%; margin: 0 auto;}

.m-l-5r{margins-left:5rem !important;}

.kfa-integrate .wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: overlay;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
@media (max-width: 1024px) {
    .kfa-integrate .wrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
}
@media (max-width: 1024px) {
    .kfa-integrate .wrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .kfa-integrate .wrapper > div {
        flex: 0 0 auto;
    }
    .kfa-integrate .wrapper > div.content {
        flex: 1 0 auto;
    }
}
.content.login.join, .content.join.join, .content.sub.join, .content.search.join {
    padding-top: 1.6rem;
    padding-bottom: 1.5rem;
}
.content.login.join .content-section, .content.join.join .content-section, .content.sub.join .content-section, .content.search.join .content-section {
    max-width: 50rem;
    margin: 0 auto;
}
#join_wrap .btns-group, #login_wrap .btns-group  { display:flex; width:100%; align-items: center; justify-content: center; margin: 1rem auto 1rem; }
#join_wrap .btns-group button, #login_wrap .btns-group button {flex: auto; border-radius: 0;}
#join_wrap .btns-group .bg-skew {z-index: 1;}
#join_wrap .btns-group.btns-group-50 button {flex: 1 0 50%;}
#join_wrap .btns-group.btns-group-sin button {max-width: 17.75rem;}
.content.login button, .content.join button, .content.sub button, .content.search button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 2.5rem;
}

#main_login_wrap .content-section, .content.login.join .content-section, .content.join.join .content-section, .content.sub.join .content-section, .content.search.join .content-section {
    background-color: #fff; border-radius: 1rem; padding: 3rem 1rem;
}
@media (min-height:800px){
    #main_login_wrap .content-section, .content.login.join .content-section, .content.join.join .content-section, .content.sub.join .content-section, .content.search.join .content-section {
        background-color: #fff; border-radius: 1rem; padding: 3rem 1rem; margin-top:10rem;
    }
}

#main{
    width: 100%;
    height: 100vh;
}

.main{
    padding-left:20px;
    vertical-align: middle;
}
.main h1{
    vertical-align:top;
    font-size: 32px;
    line-height: 1;
    margin-block-start:0px;
    display: inline-block;
    padding-right: 12px;
}

.main h1 .cursor{
    vertical-align:top;
    font-size: 32px;
    margin-block-start:0;
    padding-right: 6px;
    /*display: inline-block;*/
    animation: type .5s alternate infinite;
}

.right_div {
    width:620px;
    display: inline-block;
}
.right_div .comment {
    text-align: center;
}
.right_div .comment dd{
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}
.bottom {
    float: right;
    margin-right: 20px;
    padding-bottom: 30px;
}

.bottom.contact-info * {
    font-size: 13px;
    font-family: Pretendard, serif;
    font-weight: 500;
    line-height: 1;

}

@keyframes type{
    from{box-shadow: inset -3px 0px 0px #888;}
    to{box-shadow: inset -3px 0px 0px transparent;}
}

@media (min-width:700px) , (min-height:500px){
    .main h1{
        vertical-align:top;
        font-size: 32px;
        display: inline-block;
        padding-right: 12px;
    }
}

.w-200{width:200px !important; }
.m-l-40{margin-left: 40px; }
.m-t-10{margin-top:10px;}
.div-display-inline-b{display:inline-block;}

.btn-skew-normal-red { display: flex; align-items: center; justify-content: center; position: relative; max-width: 288px;
    color: #ffffff; font-weight: 500; height: 3.5rem; max-height:56px; font-size: 1rem; padding: 0; flex: 0 1 18rem; border-radius: 0; z-index: 1;
    background-color:#ffffff; border:0;
}
.btn-skew-normal-red::before { z-index: -1; position: absolute; top: 0; display: block; box-sizing: inherit;
    width: calc(100% - (3.5rem * 0.275637)); height: 100%; content: "";
    border-radius:1rem; background-color: #e6002d;
}

.btn-skew-normal-blue::before { z-index: -1; position: absolute; top: 0; display: block; box-sizing: inherit;
    width: calc(100% - (3.5rem * 0.275637)); height: 100%; content: "";
    border-radius:1rem; background-color: #001a58;
}
.btn-skew-normal-blue { display: flex; align-items: center; justify-content: center; position: relative; max-width: 288px;
    color: #ffffff; font-weight: 500; height: 3.5rem; max-height:56px; font-size: 1rem; padding: 0; flex: 0 1 18rem;  border-radius: 0; z-index: 1;
    background-color:#ffffff; border:0;
}

.bg-crop {
    display:inline-block;
    width:140px;
    height:190px;
    overflow:hidden;
    position:relative;
}
.bg-tiger {
    position:absolute;
    /* top:10px; */
}
.comment dl {padding-right:90px;}
