.modal-dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	/*display: none;*/
}
.modal-dialog .dim {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.85);
	z-index: 1;
}
.modal-dialog .modal-content {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}
.modal-dialog .close {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1000;
	width: 50px;
	height: 50px;
	margin: 35px;
	margin-right: 10%;
	cursor: pointer;
}
.modal-dialog .close .icon {
	width: 100%;
	height: 100%;
}

.modal-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 120px;
	font-size: 18px;
	font-weight: 700;
}
.modal-head .logo {
	margin-left: 64px;
}
.modal-body {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-flow: column;
	width: 100%;
	height: 100%;
	max-width: 1248px;
	margin : 0 auto;
	overflow-y: scroll;
}
.modal-head + .modal-body {
	padding-top: 120px;
}
.modal-body::-webkit-scrollbar {
	width: auto;
	background-color: transparent;
}
.scroll__wrap::-webkit-scrollbar-thumb {
	background-color: transparent;
	border: 0px solid transparent;
	border-radius: 0;
	cursor: pointer;
}
.scroll__wrap::-webkit-scrollbar-track {
	background-color: transparent;
}


.modal-alert {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 328px;
	transform: translate3d(-50%, -50%, 0);
	-webkit-transform: translate3d(-50%, -50%, 0);
	background: var(--color-main);
	padding: 24px;
	padding-top: 32px;
	z-index: 3000;
}
.modal-alert .title {
	color: #fff;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 20px;
	text-align: center;
}
.modal-alert .message {
	font-size: 18px;
	text-align: center;
	margin-bottom: 32px;
}
.modal-alert .title + .message {
	font-size: 16px;
	color: var(--color-nt-500);
}
.modal-alert .btn_area {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.modal-alert .btn_area .btn {
	flex-grow: 1;
	height: 48px;
	font-size: 16px;
	font-weight: 400;
}
.modal-alert .btn_area .btn + .btn {
	margin-left: 8px;
}