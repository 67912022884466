/*
 * Pretendard 
================================================================================ */
@import url('../fonts/Pretendard-1.3.3/pretendard.css');

/* sub */
:root {
	--font-Pretendard: 'Pretendard', sans-serif;
}
* {
    font-family: var(--font-Pretendard);
    font-weight: 400;
    line-height: 1.5em;
}
.poppin {
	font-family: 'poppins', sans-serif;
}
@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins/poppins-bold-webfont.eot');
    src: url('../fonts/poppins/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-bold-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-bold-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-bold-webfont.svg#poppinsbold') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins/poppins-semibold-webfont.eot');
    src: url('../fonts/poppins/poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-semibold-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-semibold-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-semibold-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins/poppins-medium-webfont.eot');
    src: url('../fonts/poppins/poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-medium-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-medium-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-medium-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-medium-webfont.svg#poppinsmedium') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins/poppins-regular-webfont.eot');
    src: url('../fonts/poppins/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-regular-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-regular-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins/poppins-light-webfont.eot');
    src: url('../fonts/poppins/poppins-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-light-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-light-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-light-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-light-webfont.svg#poppinslight') format('svg');
    font-weight: 300;
    font-style: normal;
}